<mat-card
	class="w-full overflow-hidden max-w-full rounded-xl p-2 shadow-card"
	[ngClass]="{ 'cursor-pointer': cardActions?.length > 0 }"
>
	<mat-card-header class="mb-2 flex">
		<div class="flex align-center justify-between w-full">
			<div class="flex w-full">
				<mat-icon
					class="far mr-4"
					aria-hidden="true"
					[ngClass]="[
						headerIcon,
						xlHeader ? 'text-3xl' : 'text-2xl',
						headerIconColor ? 'nbkc-' + headerIconColor : '',
					]"
				></mat-icon>
				<div class="flex-column">
					<div [ngClass]="xlHeader ? 'text-3xl' : 'text-2xl'">{{ title }}</div>
					<div mat-card-subtitle>
						{{ subtitle }}
						@if (secondSubtitle) {
							-- <span>{{ secondSubtitle }}</span>
						}
					</div>
				</div>
			</div>
			@if (headerRightIcon) {
				<div class="fal nbkc-blue pl-4" [ngClass]="[headerRightIcon, xlHeader ? 'text-6xl' : 'text-5xl']"></div>
			}
		</div>
		@if (hasCornerBanner) {
			<div class="triangle"></div>
			<i class="star fas fa-star"></i>
		}
	</mat-card-header>
	<mat-card-content>
		<!--Simple body text -->
		@if (bodyText) {
			<p class="pt-2 font-normal text-base">{{ bodyText }}</p>
		}
		<!--Any content, including a widget -->
		<ng-content></ng-content>
	</mat-card-content>
	@if (cardActions || cardActionInfo) {
		<mat-card-actions align="end">
			<!--Add words (Continue, Start) to action area without a button-->
			<p class="card-action-info justify-items-stretch">{{ cardActionInfo }}</p>
			<!--Add buttons to app-card with cardAction directive-->
			<ng-content select="[cardActions]"></ng-content>
		</mat-card-actions>
	}
</mat-card>

import { Component, OnInit, ViewChild } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { getAvailableExperiencesActivity, navigateActivity, setCurrentExperienceActivity } from '@common/lib/constants/activities';
import { ApplyEntryPoint } from '@common/lib/models/apply-entry-point';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { InflightModalComponent } from '@experience/app/components/inflight-modal/inflight-modal.component';
import { ApplicationSummary } from '@common/lib/models/application-summary';
import { LoadingService } from '@experience/app/services/loading.service';
import { PresentationService } from '@experience/app/services/presentation.service';

@Component({
	selector: 'experience-availability',
	templateUrl: './availability.component.html',
	styleUrls: ['./availability.component.scss'],
	animations: [
		trigger('listAnimation', [
			transition('* => *', [
				query(':enter', [
					style({ transform: 'translateX(-150%)' }),
					stagger(500, [
						animate('450ms', style({ transform: 'translateX(0)' }))
					])
				], { optional: true })
			])
		])
	]
})
export class AvailabilityComponent implements OnInit {
	@ViewChild(InflightModalComponent) inflightModal: InflightModalComponent;

	public newApplications: ApplyEntryPoint[];
	public existingApplications: ApplicationSummary[];
	public continuableApplications: ApplicationSummary[];
	public isShowingExistingAppModal: boolean;
	public selectedNewApp: ApplyEntryPoint;

	constructor(
		public router: Router,
		public sanitizer: DomSanitizer,
		public loadingService: LoadingService,
		private presentationService: PresentationService) {
	}

	public ngOnInit(): void {
		this.loadingService.startLoadingActivity(getAvailableExperiencesActivity);
		this.loadingService.stopLoadingActivity(navigateActivity);

		this.presentationService.getAvailableExperiences().pipe(
			take(1),
			filter((availability) => availability?.experiences?.length > 0)
		).subscribe((availability) => {
			if (availability?.inProgressApplication) {
				this.setExperience(availability.inProgressApplication);
			}

			this.newApplications = availability?.experiences;

			this.existingApplications = availability?.existing?.filter((appSum) => appSum?.entryPoint?.isVisible);
			this.continuableApplications = this.existingApplications.filter((app) => app.canContinue === true && !app.entryPoint?.actionUri);

			this.loadingService.stopLoadingActivity(getAvailableExperiencesActivity);
		});
	}

	public getExperienceIconMap(experience: ApplyEntryPoint) {
		return {
			'fa-light fa-city': experience?.applicationName?.toLowerCase()?.includes('business'),
			'fal fa-user': experience.applicationName?.toLowerCase()?.includes('personal')
		};
	}

	public handleNewExperience(entry: ApplyEntryPoint) {
		this.selectedNewApp = entry;

		if (this.continuableApplications.length === 0) {
			this.setExperience(entry);
			return;
		}

		this.inflightModal.openDialog();
	}

	public setExperience(entry: ApplyEntryPoint) {
		this.loadingService.startLoadingActivity(setCurrentExperienceActivity);

		/* eslint-disable-next-line no-extra-boolean-cast */
		if (!!entry.actionUri) {
			window.location.href = entry.actionUri;
			return;
		}

		this.presentationService.startExperience(entry).subscribe();
	}

	public createSafeHtml(text: string): SafeHtml {
		return this.sanitizer.bypassSecurityTrustHtml(text);
	}
}

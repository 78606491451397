import { AfterViewInit, Component } from '@angular/core';
import { TodoItem } from '@common/lib/models/todo-item';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Observable } from 'rxjs';
import { KnownValues } from '@common/lib/constants/known-values';
import { navigateActivity } from '@common/lib/constants/activities';
import { LoadingService } from '@experience/app/services/loading.service';
import { RoutingService } from '@experience/app/services/routing.service';
import { PresentationService } from '@experience/app/services/presentation.service';

@Component({
	selector: 'experience-todo-list',
	templateUrl: './todo-list.component.html',
	styleUrls: ['./todo-list.component.scss'],
	animations: [
		trigger('listChanged', [
			transition('* => *', [ // each time the binding value changes
				query(':enter', [
					style({
						opacity: 0
					}),
					stagger(100, [
						animate('200ms', style({
							opacity: 1
						}))
					])
				], { optional: true })
			])
		])
	]
})
export class TodoListComponent implements AfterViewInit {

	public todoList$: Observable<TodoItem[]>;
	public currentRoute$: Observable<string>;
	public animationsDisabled: boolean = true;

	constructor(
		private presentationService: PresentationService,
		private loadingService: LoadingService,
		private routingService: RoutingService
	) {
		this.todoList$ = this.presentationService.todoList$.asObservable();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.animationsDisabled = false;
		});
	}

	public isActiveScreen(associatedRoute) {
		return associatedRoute === this.routingService.currentRoute;
	}

	public navigateToRoute(item: TodoItem): void {
		const { associatedRoute, complete } = item;
		if (complete && associatedRoute) {
			const fullRoute = `${KnownValues.workflowPath}${associatedRoute}`;
			this.routingService.setCurrentRoute(associatedRoute);
			this.routingService.navigateToUrl$(fullRoute).subscribe(() => {
				this.loadingService.stopLoadingActivity(navigateActivity);
			});
		}
	}
}

import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { ApplyEntryPointResource } from '@common/lib/models/resource/apply-entry-point-resource';
import { ApplyEntryPoint } from '@common/lib/models/apply-entry-point';


export class ApplyEntryPointResourceToApplyEntryPointAdapter extends AdapterBase<ApplyEntryPointResource, ApplyEntryPoint> {
	adapt(source: ApplyEntryPointResource): ApplyEntryPoint {
		if (!source) {
			return null;
		}

		const dest = new ApplyEntryPoint();
		dest.title = source.displayName;
		dest.version = source.version;
		dest.description = source.displayDescription;
		dest.applicationId = source.applicationId;
		dest.applicationName = source.applicationName;
		dest.actionDescription = source.actionDescription;
		dest.actionUri = source.actionUri;
		return dest;
	}
}


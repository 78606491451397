import { Component, Input, ViewChild, TemplateRef, ViewEncapsulation, EventEmitter, Output, OnChanges, inject } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ApplyEntryPoint } from '@common/lib/models/apply-entry-point';
import _ from 'lodash';
import { ApplicationSummary } from '@common/lib/models/application-summary';

@Component({
	selector: 'experience-inflight-modal',
	templateUrl: './inflight-modal.component.html',
	styleUrls: ['./inflight-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class InflightModalComponent implements OnChanges {
	@Input() continuableApplications: ApplicationSummary[];
	@Input() newApplication: ApplyEntryPoint;

	@Output() experienceSelected = new EventEmitter<ApplyEntryPoint>();

	@ViewChild('confirmModal', { static: true }) confirmModal: TemplateRef<any>;

	readonly dialog = inject(MatDialog);

	latestApp: ApplicationSummary;

	constructor() { }

	ngOnChanges() {
		const sortedApplications = _.sortBy(this.continuableApplications, (application) => Date.parse(application.startDate));
		this.latestApp = _.last(sortedApplications);
	}

	selectExperience(selectedExperience: ApplyEntryPoint) {
		this.experienceSelected.emit(selectedExperience);
		this.closeDialog();
	}

	closeDialog() {
		this.dialog.closeAll();
	}

	public openDialog() {
		const dialogConfig: MatDialogConfig = {
			id: 'inflight-modal-element',
			// height: auto,
			width: '475px',
			autoFocus: false
		};

		this.dialog.open(this.confirmModal, dialogConfig);
	}
}

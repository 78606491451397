import { Injectable } from '@angular/core';
import { CommonConfiguration } from '@common/lib/utilities/configuration/common.configuration';

@Injectable({
	providedIn: 'root'
})
export abstract class ExperienceConfiguration extends CommonConfiguration {
	public stateDebuggerEnabled: boolean;
	public defaultRoute: string;
	public addressSearchApi: string;
	public appInsightsKey: string;
	public vouchedPublicKey: string;
	public vouchedSandboxKey: string;
	public vouchedDefaultToSandbox: boolean;
	public turnOnSocureDevicer: boolean;
	public socureDevicerPublicKey: string;
	public q2LoginFormURL: string;
}

<div class="mt-20">
	<div class="availability-content">
		<div class="text-3xl text-center pb-10 md:pb-0 md:text-left md:text-5xl font-bold leading-12">
			Welcome to nbkc bank!
		</div>
		<div class="pt-2 pb-2 text-base text-gray-600 mt-4 mb-4">Let’s get your new account opened!</div>

		@if (pendingApplications()?.length > 0) {
			<div class="flex items-center base-divider blue separator mt-10 mb-10">In Progress Applications</div>
			<div [@listAnimation]="pendingApplications().length">
				@for (application of pendingApplications(); track application.id) {
					<div class="card-div-container flex justify-center mb-4">
						<app-card
							[title]="application.kind === 'Personal' ? 'Personal Account' : 'Business Account'"
							[subtitle]="'Started on ' + (application.startDate | date: 'mediumDate')"
							[secondSubtitle]="ONBOARDING_SUMMARY_CARDS[application.status].statusDescription"
							[bodyText]="ONBOARDING_SUMMARY_CARDS[application.status].description"
							[headerIcon]="ONBOARDING_SUMMARY_CARDS[application.status].icon"
							[headerIconColor]="ONBOARDING_SUMMARY_CARDS[application.status].headerIconColor"
							[attr.tabindex]="application.status !== 'OnHold' ? 0 : null"
							[class.cursor-pointer]="application.status !== 'OnHold'"
							(keydown.enter)="createEntryPointAndSetExperience(application)"
							(click)="createEntryPointAndSetExperience(application)"
							[cardActionInfo]="
								application.status === 'Approved'
									? 'Go to nbkc.com'
									: application.status !== 'OnHold' && 'Continue'
							"
							data-cy="selectable-card"
							class="w-full"
						>
						</app-card>
					</div>
				}
			</div>
		}

		<div class="flex items-center base-divider blue separator mt-10 mb-10">Start a New Application</div>
		<div [@listAnimation]="newApplications.length">
			@for (kind of newApplications; track kind) {
				<div class="card-div-container flex justify-center mb-4">
					<app-card
						[title]="NEW_APPLICATION_SUMMARY_CARDS[kind].title"
						[headerIcon]="NEW_APPLICATION_SUMMARY_CARDS[kind].icon"
						[headerIconColor]="NEW_APPLICATION_SUMMARY_CARDS[kind].headerIconColor"
						[bodyText]="NEW_APPLICATION_SUMMARY_CARDS[kind].description"
						(click)="startNewApplication(kind)"
						(keydown.enter)="startNewApplication(kind)"
						cardActionInfo="Start"
						data-cy="selectable-card"
						class="w-full cursor-pointer"
					></app-card>
				</div>
			}
		</div>

		<div class="flex items-center base-divider blue separator mt-10 mb-10">Closed Applications</div>
		@if (closedApplications()?.length > 0) {
			<div [@listAnimation]="closedApplications().length">
				@for (application of closedApplications(); track application.id) {
					<div class="card-div-container flex justify-center mb-4">
						<app-card
							[title]="application.kind === 'personal' ? 'Personal Account' : 'Business Account'"
							subtitle="Started on {{ application.startDate | date: 'mediumDate' }}"
							[secondSubtitle]="ONBOARDING_SUMMARY_CARDS[application.status].statusDescription"
							[bodyText]="
								ONBOARDING_SUMMARY_CARDS[application.status].description +
								' ' +
								(application.modifiedDate | date: 'mediumDate') +
								'.'
							"
							[headerIcon]="ONBOARDING_SUMMARY_CARDS[application.status].icon"
							[headerIconColor]="ONBOARDING_SUMMARY_CARDS[application.status].headerIconColor"
							class="w-full"
						>
						</app-card>
					</div>
				}
			</div>
		}
	</div>

	<div class="fixed -z-10 top-2/4 right-0 transform -translate-y-1/2 invisible md:visible">
		<img alt="side-photo" ngSrc="/assets/availability-logo.svg" width="1000" height="752" priority />
	</div>
</div>

<mat-form-field [formGroup]="formGroup" class="w-full" appearance="outline">
	<mat-label>Password</mat-label>
	<input
		matInput
		id="password"
		name="password"
		formControlName="password"
		[errorStateMatcher]="matcher"
		(focusout)="onPasswordBlur()"
		[type]="passwordIsVisible ? 'text' : 'password'"
		data-cy="input-field"
	/>
	<button
		type="button"
		mat-icon-button
		matSuffix
		class="password-visibility-toggle"
		(click)="togglePasswordVisibility()"
	>
		<i
			class="far"
			[ngClass]="{ 'fa-eye': passwordIsVisible === true, 'fa-eye-slash': passwordIsVisible === false }"
		></i>
	</button>
</mat-form-field>
<span *ngIf="passwordFormControl.errors && passwordFormControl.dirty" [ngSwitch]="getValidationError()">
	<span *ngSwitchCase="'specialCharacters'">
		<div class="pl-2.5 pt-2.5 validation-message">
			Can only contain the following special characters: !&#64;#$%^*
		</div>
	</span>
	<span *ngSwitchCase="'maxlength'">
		<div class="pl-2.5 pt-2.5 validation-message">Password too long</div>
	</span>
	<span *ngSwitchCase="'required'">
		<div class="pl-2.5 pt-2.5 validation-message">Please enter your new online banking password</div>
	</span>
</span>
<div class="password-validation-container">
	<div
		*ngFor="let passwordRequirement of passwordRequirements"
		class="password-validation"
		[ngClass]="{ 'validation-met': passwordRequirement.isValid === true }"
	>
		<i class="fas fa-circle password-validation-icon"></i>
		<span class="password-validation-text">{{ passwordRequirement.message }}</span>
	</div>
</div>

import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { NgClass } from '@angular/common';

@Component({
	selector: 'app-card',
	standalone: true,
	imports: [MatCardModule, NgClass],
	templateUrl: './app-card.component.html',
	styleUrl: './app-card.component.scss',
})
export class AppCardComponent {
	@Input() title: string;
	@Input() subtitle: string;
	@Input() secondSubtitle: string;
	@Input() headerIcon: string = ''; //font awesome icon class
	@Input() headerIconColor: string = 'blue'; // for supported options, see colors section in styles.scss
	@Input() headerRightIcon: string = '';
	@Input() hasCornerBanner: boolean = false;
	@Input() xlHeader: boolean = false;
	@Input() leftActionButton: string;
	@Input() rightActionButton: string;
	@Input() bodyText: string;
	@Input() cardActions: string[] = null;
}

import { TodoItem } from '@common/lib/models/todo-item';

export const personalTodoItems: TodoItem[] = [
	{
		title: 'Select Your Account',
		associatedRoute: 'product-selection',
		complete: false,
	},
	{
		title: 'Tell Us About Yourself',
		associatedRoute: 'primary-holder',
		complete: false,
	},
	{
		title: 'Set Up Your Account',
		associatedRoute: 'account-ownership',
		complete: false,
	},
	{
		title: 'Order Debit Card',
		associatedRoute: 'debit-card',
		complete: false,
	},
	{
		title: 'Review Disclosures',
		associatedRoute: 'application-review',
		complete: false,
	},
	{
		title: 'Set Up Online Banking',
		associatedRoute: 'online-banking',
		complete: false,
	},
];

// /*
// TODO: resolve the following routes:
//
//  What to do with?:
//   subroutes from Tell Us About yourself
//   'Add More People' screen (route additional-holders),
//   'Tell Us About This Individual' screen (holder-details)
//   Other routes:
//      'manual-review'
//      'application-pending'
//      'processing', 'denied', 'archived', 'withdrawn', 'pending'
//
//  */

import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OnboardingApplicationSummary } from '@experience/app/models/onboarding-application-summary.model';
import { ExperienceConfiguration } from '@experience/app/experience.configuration';

@Injectable({
	providedIn: 'root',
})
export class ApplicationService {
	readonly config = inject(ExperienceConfiguration);
	private baseUrl = `${this.config.experienceApi}/applications`;
	private http = inject(HttpClient);

	constructor() {}

	getApplicationSummaries(): Observable<OnboardingApplicationSummary[]> {
		return this.http.get<OnboardingApplicationSummary[]>(`${this.baseUrl}`);
	}
}

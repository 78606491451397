import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationUtility } from '@common/lib/utilities/authentication/authentication.utility';
import { navigateActivity } from '@common/lib/constants/activities';
import { LoadingService } from '@experience/app/services/loading.service';
import { PresentationService } from '@experience/app/services/presentation.service';
import { RoutingService } from '@experience/app/services/routing.service';

@Component({
	selector: 'experience-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
	public previousRoute: string = '';
	public options: HeaderComponentOptions;

	private destroy$: Subject<void> = new Subject<void>();

	constructor(
		public auth: AuthenticationUtility,
		private loadingService: LoadingService,
		private presentationService: PresentationService,
		private routingService: RoutingService
	) {
		this.presentationService.currentPresentationDefinition$.subscribe(currentPresentationDefinition => {
			this.options = {isHidden: currentPresentationDefinition?.header?.options?.isHidden};
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	public goToAvailability(): void {
		this.loadingService.startLoadingActivity(navigateActivity);
		this.presentationService.resetExperience();
		this.routingService.navigateToUrl$('/').subscribe();
	}
}

interface HeaderComponentOptions {
	isHidden: string;
}

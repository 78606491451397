<h2 mat-dialog-title class="flex mb-4">
	<div class="text-5xl mr-4">
		<i class="fa-light fa-block-question"></i>
	</div>
	<div>Are you sure you want to start a new application?</div>
</h2>

<mat-dialog-content class="pb-3 mat-typography">
	<div>
		<p>
			You have an application in progress that was started {{ data.lastStartDate | date: 'mediumDate' }}. Are you
			sure you want to start another?
		</p>
		<button mat-button color="primary" class="p-2 mt-2" [mat-dialog-close]="'continue'">ContinueApplication</button>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button color="warn" mat-dialog-close>Cancel</button>
	<button mat-button color="primary" [mat-dialog-close]="true">Start a new application</button>
</mat-dialog-actions>

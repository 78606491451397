import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { ApplyAppSummaryResource } from '@common/lib/models/resource/apply-app-summary-resource';
import { ApplicationSummary } from '@common/lib/models/application-summary';
import { ApplyEntryPoint } from '@common/lib/models/apply-entry-point';
import { DateFormatter } from '@common/lib/utilities/date-formatter.utility';

export class ApplyAppSummaryResourceToApplyEntryPointAdapter extends AdapterBase<ApplyAppSummaryResource, ApplicationSummary> {
	adapt(source: ApplyAppSummaryResource): ApplicationSummary {
		if (!source) {
			return null;
		}

		const dateFormat = { weekday: 'long', month: 'long', day: 'numeric' } as const;
		const startDate = new Date(source.startDate);
		const ordinal = DateFormatter.getOrdinaltoDisplay(startDate.getDate());

		const dest = new ApplicationSummary();
		dest.applicationName = source.name;
		dest.description = source.description;
		dest.startDate = startDate.toLocaleDateString('en-us', dateFormat) + ordinal;
		dest.phase = source.phase;
		dest.canContinue = !!source.entryPoint?.actionDescription;

		dest.entryPoint = new ApplyEntryPoint();
		dest.entryPoint.applicationId = source.entryPoint?.applicationId;
		dest.entryPoint.applicationName = source.entryPoint?.applicationName;
		dest.entryPoint.actionDescription = source.entryPoint?.actionDescription;
		dest.entryPoint.actionUri = source.entryPoint?.actionUri;
		dest.entryPoint.isVisible = source.entryPoint?.isVisible;

		return dest;
	}

}

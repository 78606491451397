import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteType } from '@common/lib/models/enum/route-type.enum';
import { PresentationDefinition } from '@common/lib/models/presentation-definition';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  public currentRoute: string;
  public initialRoute: string;

  constructor(private router: Router) { }

  public navigateToUrl$(route: string): Observable<boolean> {
		return from(this.router.navigateByUrl(route));
	}

  public setInitialRoute(value: string) {
    this.initialRoute = value;
  }

  public setCurrentRoute(value: string) {
	this.currentRoute = value;
  }

  public getRoute(routeType: RouteType, presentationDefinitions: PresentationDefinition[]): string {
		switch (routeType) {
			case RouteType.next:
				return presentationDefinitions.find(presDef => presDef.route === this.currentRoute).next;
			case RouteType.previous:
				return presentationDefinitions.find(presDef => presDef.route === this.currentRoute).previous;
			case RouteType.initial:
				return this.initialRoute;
			default:
				return null;
		}
	}


}

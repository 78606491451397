import { Injectable, signal } from '@angular/core';
import { TodoItem } from '@common/lib/models/todo-item';
import { personalTodoItems } from '@experience/app/models/personal-todo-items.model';
import { businessTodoItems } from '@experience/app/models/business-todo-items.model';

@Injectable({
	providedIn: 'root',
})
export class ApplicationStateService {
	private readonly state = {
		$isOldExperience: signal<boolean>(true),
		$todoItems: signal<TodoItem[]>([]),
		$applicationKind: signal<string>(''),
	};

	readonly $isOldExperience = this.state.$isOldExperience.asReadonly(); // isUpConverted
	readonly $todoItems = this.state.$todoItems.asReadonly();
	readonly $applicationKind = this.state.$applicationKind.asReadonly();

	constructor() {}

	setTodoItems(todoItems: TodoItem[]) {
		this.state.$todoItems.set([...todoItems]);
	}

	setIsOldExperience(isOldExperience: boolean) {
		this.state.$isOldExperience.set(isOldExperience);
	}

	setApplicationKind(kind: string) {
		this.state.$applicationKind.set(kind);
		if (this.state.$applicationKind() === 'personal') {
			this.state.$todoItems.set([...personalTodoItems]);
			return;
		}
		this.state.$todoItems.set([...businessTodoItems]);
	}
}

import { Injectable } from '@angular/core';
import { ScreenResourceToPresentationDefinitionAdapter } from '@common/lib/adapters/screen-resource-to-presentation-definition.adapter';
import { DocumentInfo } from '@common/lib/models/document-info';
import { RouteType } from '@common/lib/models/enum/route-type.enum';
import { ApplyFlowRequestResource } from '@common/lib/models/resource/apply-flow-request-resource';
import { StorageUtility } from '@common/lib/utilities/storage-utility';
import { from } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { ConditionResourceAdapter } from '@experience/app/adapters/condition-resource.adapter';
import { DocumentUploadCompleteAdapter } from '@experience/app/adapters/document-upload-complete.adapter';
import { RequestDocumentUploadAdapter } from '@experience/app/adapters/request-document-upload.adapter';
import { VerifyIdAdapter } from '@experience/app/adapters/verify-id.adapter';
import { ExperienceClient } from '@experience/app/clients/experience.client';
import { DocumentUploadComplete } from '@experience/app/models/document-upload-complete.model';
import { DocumentUploadDetails } from '@experience/app/models/document-upload-details.model';
import { RequestDocumentUpload } from '@experience/app/models/request-document-upload.model';
import { VerifyId } from '@experience/app/models/verify-id.model';
import { ModelStateService } from '@experience/app/services/model-state.service';
import { PresentationService } from '@experience/app/services/presentation.service';
import { RoutingService } from '@experience/app/services/routing.service';

@Injectable({
	providedIn: 'root'
})
export class DocumentService {

	constructor(
		private experienceClient: ExperienceClient,
		private presentationService: PresentationService,
		private modelStateService: ModelStateService,
		private routingService: RoutingService,
		private storageUtility: StorageUtility
	) { }

	public uploadDocument() {

	}

	public requestDocumentUpload(uploadRequest: RequestDocumentUpload) {
		return from([uploadRequest]).pipe(
			concatMap((documentUploadRequest) => {
				const requestBody = new ApplyFlowRequestResource();
				const requestDocUploadEventAdapter = new RequestDocumentUploadAdapter();
				requestBody.presentationEvent = requestDocUploadEventAdapter.adapt(documentUploadRequest as RequestDocumentUpload);
				return this.experienceClient.flow$(requestBody, this.presentationService.applicationId);
			}),
			map((result) => {
				if (result) {
					if (result?.confirmation) {
						const conditionToUpload =
							this.modelStateService.conditions$.getValue()
								.find((condition) =>
									condition.id ?
										condition.id === result?.confirmation.conditionId :
										condition.name === result?.confirmation.conditionName);

						conditionToUpload.status = result?.confirmation.status;

						const dest = new DocumentUploadDetails();
						dest.container = result?.confirmation.container;
						dest.conditionName = result?.confirmation.conditionName;
						dest.uri = result?.confirmation.uri;
						return dest;
					}
				}
				return;
			})
		);
	}

	public saveDocumentUploaded(documentUpload: DocumentUploadComplete) {

		from([documentUpload]).pipe(
			concatMap((documentUploadComplete) => {
				const requestBody = new ApplyFlowRequestResource();
				const documentUploadCompleteAdapter = new DocumentUploadCompleteAdapter();
				requestBody.presentationEvent = documentUploadCompleteAdapter.adapt(documentUploadComplete);
				return this.experienceClient.flow$(requestBody, this.presentationService.applicationId);
			}),
			map((result) => {
				if (result) {
					if (result?.confirmation) {
						const completedCondition =
							result?.snapshot.model.conditions
								.find((condition) =>
									condition.id ?
										condition.id === result?.confirmation.conditionId :
										condition.name === result?.confirmation.conditionName);

						completedCondition.status = result?.confirmation.status;
						this.modelStateService.updateConditions(result?.snapshot.model.conditions);
					}
				}
			})
		).subscribe();
	}

	public verifyId(documentVerification: VerifyId) {
		from([documentVerification]).pipe(
			concatMap((documentVerificationRequest) => {
				const requestBody = new ApplyFlowRequestResource();
				const verifyIdAdapter = new VerifyIdAdapter();
				requestBody.presentationEvent = verifyIdAdapter.adapt(documentVerificationRequest);
				return this.experienceClient.flow$(requestBody, this.presentationService.applicationId);
			}),
			map((response) => {
				if (response.snapshot?.experience) {
					const routesAvaliable = response.snapshot.experience.availableRoutes;
					const adapterOptions: any = { availableRoutes: routesAvaliable };

					const adapter = new ScreenResourceToPresentationDefinitionAdapter();
					const adaptedDefinition = adapter.adaptCollection(response.snapshot.experience?.screens, adapterOptions);
					this.presentationService.presentationDefinitions$.next(adaptedDefinition);
				}

				if (response.snapshot.model.conditions) {
					const conditionAdapter = new ConditionResourceAdapter();
					this.modelStateService.updateConditions(conditionAdapter.adapt(response.snapshot.model.conditions));
				}

				if (this.routingService.getRoute(RouteType.next, this.presentationService.presentationDefinitions$.getValue())) {
					this.presentationService.navigateByRouteType(RouteType.next);
				}
				return;
			})
		).subscribe();
	}

	public postDocuments$(files: DocumentInfo[], containerName: string, sasUrl: string) {
		return from(this.storageUtility.postDocuments$(files, containerName, sasUrl));
	}
}

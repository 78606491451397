import { ApplyEntryPoint } from './apply-entry-point';

export class ApplicationSummary {
	public applicationName: string;
	public startDate: string;
	public phase: string;
	public description: string;
	public entryPoint: ApplyEntryPoint;
	public canContinue: boolean;
}

import { TodoItem } from '@common/lib/models/todo-item';

export const businessTodoItems: TodoItem[] = [
	{
		title: 'Prohibited Businesses',
		associatedRoute: 'prohibited-businesses',
		complete: false,
	},
	{
		title: 'Tell Us About Yourself',
		associatedRoute: 'primary-signer',
		complete: false,
	},
	{
		title: 'Business Structure',
		associatedRoute: 'account-ownership',
		complete: false,
	},
	{
		title: 'Tell Us About Your Business',
		associatedRoute: 'business-profile',
		complete: false,
	},
	{
		title: 'Business Expectations',
		associatedRoute: 'business-expectations',
		complete: false,
	},
	{
		title: 'Add More People',
		associatedRoute: 'additional-signers',
		complete: false,
	},
	{
		title: 'Product Selection',
		associatedRoute: 'product-selection',
		complete: false,
	},
	{
		title: 'Order Debit Card',
		associatedRoute: 'debit-card',
		complete: false,
	},
	{
		title: 'Review and Disclosures',
		associatedRoute: 'application-review',
		complete: false,
	},
	{
		title: 'Set Up Online Banking',
		associatedRoute: 'setup-online-banking',
		complete: false,
	},
	{
		title: 'Application Received',
		associatedRoute: 'manual-review',
		complete: false,
	},
];

// /*
// TODO: resolve the following routes:
//
//  What to do with?:
//
//   From 'Add More People' screen (route signer-details) (then returns to additional-signers)
//   Other routes:
//      'application-pending'
//      'submitted', 'processing', 'denied', 'archived', 'withdrawn', 'pending'
//
//  */

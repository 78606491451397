import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from '@experience/app/components/error/error.component';
import { AuthenticationGuard } from '@common/lib/utilities/authentication/guards/authentication.guard';
import { LoginComponent } from '@experience/app/components/login/login.component';
import { AvailabilityComponent } from '@experience/app/components/availability/availability.component';
import { LogoutComponent } from '@experience/app/components/logout/logout.component';

export const defaultRoutes: Routes = [
	{
		path: 'error',
		component: ErrorComponent
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'logout',
		component: LogoutComponent
	},
	{
		path: '',
		component: AvailabilityComponent,
		canActivate: mapToCanActivate([AuthenticationGuard])
	},
	{
		path: '**',
		redirectTo: '',
	}
];

@NgModule({
	imports: [RouterModule.forRoot(defaultRoutes, { scrollPositionRestoration: 'top' })],
	exports: [RouterModule]
})
export class AppRoutingModule {
}

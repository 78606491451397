import { AfterViewInit, ChangeDetectorRef, ContentChild, Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

// Angular material does not currently support adding required asterisks based on reactive forms, this fixes that.
// taken from https://github.com/angular/components/issues/2574 this is @kwkelly's improvement on @bboehm86's solution.
export abstract class CustomMatFormFieldControl<T> extends MatFormFieldControl<T> {
	required: boolean;
}

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: 'mat-form-field',
})
export class MatFormFieldRequiredDirective implements AfterViewInit {
	@ContentChild(MatFormFieldControl) control: CustomMatFormFieldControl<any>;

	constructor(private cdRef: ChangeDetectorRef) { }

	ngAfterViewInit(): void {
		if (this.control && this.control.ngControl) {
			const validator =
				this.control.ngControl.validator || this.control.ngControl['form'] // [formControl] standalone
					? this.control.ngControl['form'].validator
					: this.control.ngControl.control // formControlName in FromGroup
						? this.control.ngControl.control.validator
						: null;
			if (validator) {
				Promise.resolve().then(() => {
					const vf = validator(new FormControl());
					this.control.required = vf ? vf.required : null;
					this.cdRef.markForCheck();
				});
			}
		}
	}

}

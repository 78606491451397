<div *ngIf="reviewData$ | async as reviewData">
	<div *ngIf="reviewData.reviewItems && reviewData.reviewItems.length > 0">
		<div class="base-divider blue separator">Complete before you proceed</div>
		<div [@listAnimation]="reviewData.reviewItems.length">
			<div class="card-div-container flex justify-center mb-6" *ngFor="let result of reviewData.reviewItems">
				<mat-card
					appearance="outlined"
					tabindex="0"
					data-cy="selectable-card"
					class="w-full overflow-hidden cursor-pointer rounded-xl shadow-card p-4"
					(keydown.enter)="reviewResult(result)"
					(click)="reviewResult(result)"
				>
					<mat-card-title class="flex justify-between">
						<div class="flex">
							<i class="far fa-exclamation-square text-3xl mr-3 self-center"></i>
							<span class="max-w-xs review-message whitespace-pre-wrap self-center">{{
								result.message
							}}</span>
						</div>
						<div *ngIf="result.targetScreen" class="link-text font-medium self-center">Go to Section</div>
					</mat-card-title>
				</mat-card>
			</div>
		</div>
	</div>
	<div *ngIf="uploadConditions as conditions">
		<div *ngIf="conditions && conditions.length > 0">
			<div class="base-divider separator">Items we need from you</div>
			<div *ngFor="let result of conditions">
				<div [ngSwitch]="result.conditionType">
					<common-identity-verification-vouched
						*ngSwitchCase="'DocumentVerification'"
						[firstName]="firstName$ | async"
						[lastName]="lastName$ | async"
						[phoneNumber]="phoneNumber$ | async"
						[email]="email$ | async"
						[condition]="result"
					>
					</common-identity-verification-vouched>
					<common-manual-upload *ngSwitchCase="'Document'" [condition]="result"> </common-manual-upload>
				</div>
			</div>
		</div>

		<div
			*ngIf="
				routingService.currentRoute === 'application-review' &&
				(!reviewData.reviewItems || reviewData.reviewItems.length === 0) &&
				(!conditions || conditions.length === 0)
			"
		>
			<div class="card-div-container flex justify-center mb-6">
				<mat-card
					appearance="outlined"
					class="w-full overflow-hidden flex-col items-start justify-between rounded-xl shadow-card"
					data-cy="card"
				>
					<div class="flex items-center mb-2">
						<i class="far fa-check text-4xl mr-4 review-icon"></i>
						<span class="text-lg font-semibold"> Everything Looks Good</span>
					</div>
					<div>
						<span class="review-confirm-text text-base">
							By clicking next you confirm that you had the opportunity to review the legal disclosures.
						</span>
					</div>
				</mat-card>
			</div>
		</div>

		<div *ngIf="routingService.currentRoute === 'manual-review' && (!conditions || conditions.length === 0)">
			<div class="card-div-container flex justify-center mb-6">
				<mat-card
					appearance="outlined"
					class="w-full overflow-hidden flex-col items-start justify-between rounded-xl shadow-card"
					data-cy="card"
				>
					<div>
						<span class="text-base">
							We're gathering a list of items we need from you. Check back in a bit and refresh this page
							in a few minutes to see what we need.
						</span>
					</div>
				</mat-card>
			</div>
		</div>
	</div>
	<div *ngIf="!hideSubmit">
		<button
			id="submit-application-button-{{ id }}"
			class="w-full text-white rounded-full py-2 px-4"
			[disabled]="hasReviewItems"
			mat-raised-button
			color="primary"
			data-cy="submit-button"
			(click)="submit()"
		>
			Next
		</button>
	</div>
</div>

<div class="mt-20">
	<div class="availability-content">
		<div class="text-3xl text-center pb-10 md:pb-0 md:text-left md:text-5xl font-bold leading-12">
			Welcome to
			nbkc bank!
		</div>
		<div class="pt-2 pb-2 text-base text-gray-600 mt-4 mb-4">Let’s get your new account opened!</div>
		<div *ngIf="newApplications?.length as rows">
			<div class="flex items-center base-divider blue separator mt-10 mb-10">Start a New Application</div>
			<div [@listAnimation]="rows">
				<div class="card-div-container flex justify-center mb-4" *ngFor="let newApplication of newApplications">
					<mat-card appearance="outlined" tabindex="0"
						class="w-full overflow-hidden max-w-full rounded-xl shadow-card p-5 cursor-pointer"
						(keydown.enter)="handleNewExperience(newApplication)"
						(click)="handleNewExperience(newApplication)"
						data-cy="newapplication">
						<div class="flex items-start justify-between">
							<div class="title-row">
								<div class="icon-container">
									<div class="card-icon" [ngClass]="getExperienceIconMap(newApplication)"></div>
								</div>
								<div class="block">
									<div class="application-title">{{newApplication.title}}</div>
								</div>
							</div>
						</div>
						<div class="block">
							<div class="application-description">{{newApplication.description}}</div>
						</div>
						<div class="action-container grid justify-items-strech">
							<div class="action-right">Start</div>
						</div>
					</mat-card>
				</div>


			</div>
		</div>
		<div *ngIf="existingApplications?.length as rows">
			<div class="flex items-center base-divider blue separator mt-10 mb-10">Pending Applications</div>
			<div [@listAnimation]="rows">
				<div class="existing-applications card-div-container flex justify-center mb-4" *ngFor="let existingApplication of existingApplications">
					<mat-card appearance="outlined" [attr.tabindex]="existingApplication.canContinue ? 0 : null"
						class="w-full overflow-hidden max-w-full rounded-xl shadow-card p-5"
						[ngClass]="{'cursor-pointer': existingApplication.canContinue}"
						(keydown.enter)="setExperience(existingApplication.entryPoint)"
						(click)="setExperience(existingApplication.entryPoint)"
						data-cy="selectable-card">
						<div class="flex items-start justify-between">
							<div class="title-row">
								<div class="icon-container">
									<div class="card-icon" [ngClass]="getExperienceIconMap(existingApplication.entryPoint)"></div>
								</div>
								<div class="block">
									<div class="application-title">{{existingApplication.applicationName}}</div>
									<div class="application-subtitle">Started on {{existingApplication.startDate}}</div>
									<div class="application-subtitle">{{existingApplication.phase}}</div>
								</div>
							</div>
						</div>

						<div class="block">
							<div class="application-description"
									[innerHTML]="createSafeHtml(existingApplication.description)"></div>
						</div>
						<div class="action-container grid justify-items-strech">
							<div *ngIf="existingApplication.canContinue" class="action-right">
								{{existingApplication.entryPoint.actionDescription}}
							</div>
						</div>
					</mat-card>
				</div>
			</div>
		</div>
	</div>
	<div class="fixed -z-10 top-1/2 right-0 transform -translate-y-1/2 invisible md:visible">
		<img alt="side-photo" src="/assets/availability-logo.svg">
	</div>
	<experience-inflight-modal
		[continuableApplications]="continuableApplications"
		[newApplication]="selectedNewApp"
		(experienceSelected)="setExperience($event)">
	</experience-inflight-modal>
</div>

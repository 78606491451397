import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { DatePipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { Router } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
	navigateActivity,
	getApplicationsActivity,
	setCurrentExperienceActivity,
} from '@common/lib/constants/activities';
import { GeneralError } from '@common/lib/models/general-error.model';
import { CommonErrorService } from '@common/lib/services/common-error.service';
import { ApplyEntryPoint } from '@common/lib/models/apply-entry-point';
import { AppCardComponent } from '@experience/app/components-new/card/app-card.component';
import { LoadingService } from '@experience/app/services/loading.service';
import { PresentationService } from '@experience/app/services/presentation.service';
import { ApplicationService } from '@experience/app/services/application.service';
import { OnboardingApplicationSummary } from '@experience/app/models/onboarding-application-summary.model';
import {
	ONBOARDING_SUMMARY_CARDS,
	NEW_APPLICATION_SUMMARY_CARDS,
} from '@experience/app/constants/onboarding-summary-cards';
import { MatDialog } from '@angular/material/dialog';
import { NewAppDialogComponent } from '../new-app-dialog/new-app-dialog.component';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
export interface DialogData {
	lastStartDate: string;
}

@Component({
	selector: 'app-landing-page',
	standalone: true,
	imports: [MatCardModule, NgForOf, NgIf, AppCardComponent, NgOptimizedImage, DatePipe],
	templateUrl: './landing-page.component.html',
	styleUrl: './landing-page.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('listAnimation', [
			transition('* => *', [
				query(
					':enter',
					[
						style({ transform: 'translateX(-150%)' }),
						stagger(500, [animate('450ms', style({ transform: 'translateX(0)' }))]),
					],
					{ optional: true },
				),
			]),
		]),
	],
})
export class LandingPageComponent implements OnInit, OnDestroy {
	loadingService = inject(LoadingService);
	private applicationStateService = inject(ApplicationStateService);
	private applicationService = inject(ApplicationService);
	private presentationService = inject(PresentationService);
	errorService = inject(CommonErrorService);
	router = inject(Router);
	readonly confirmNewAppDialog = inject(MatDialog);

	pendingApplications = signal<OnboardingApplicationSummary[]>([]);
	closedApplications = signal<OnboardingApplicationSummary[]>([]);
	newApplications = ['personal', 'business'];
	private subscription: Subscription = new Subscription();

	constructor() {}

	ngOnInit(): void {
		this.loadingService.startLoadingActivity(getApplicationsActivity);
		this.loadingService.stopLoadingActivity(navigateActivity);
		this.applicationService
			.getApplicationSummaries()
			.pipe(
				finalize(() => {
					this.loadingService.stopLoadingActivity(getApplicationsActivity);
				}),
			)
			.subscribe({
				next: (applicationSummaries) => {
					this.sortSummariesByStatusAndDate(applicationSummaries);
				},
				error: (err: GeneralError) => this.errorService.handleError(err),
			});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	createEntryPointAndSetExperience(application: OnboardingApplicationSummary): void {
		//TODO: check is upconverted and do this, or call method (needs to be created) for new bluefin
		this.loadingService.startLoadingActivity(setCurrentExperienceActivity);
		const entryPoint = new ApplyEntryPoint();
		entryPoint.applicationId = application.id;
		entryPoint.applicationName = `deposits-${application.kind.toLowerCase()}`;
		this.loadingService.startLoadingActivity(setCurrentExperienceActivity);

		if (application.status === 'Onboarded') {
			window.location.href = 'https://nbkc.com';
			return;
		}
		this.applicationStateService.setIsOldExperience(application.isUpconverted);
		this.presentationService.startExperience(entryPoint).subscribe();
	}

	startNewApplication(applicationKind: string): void {
		const entryPoint = new ApplyEntryPoint();
		entryPoint.applicationName = `deposits-${applicationKind.toLowerCase()}`;
		entryPoint.version = '1.0';
		if (this.pendingApplications().length === 0) {
			this.startExperience(entryPoint);
		}
		const dialogRef = this.confirmNewAppDialog.open(NewAppDialogComponent, {
			width: '400px',
			data: { lastStartDate: this.pendingApplications()[0].startDate },
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result === true) {
				this.startExperience(entryPoint);
			} else if (result === 'continue') {
				this.createEntryPointAndSetExperience(this.pendingApplications()[0]);
			}
		});
	}

	private startExperience(entryPoint: ApplyEntryPoint): void {
		this.loadingService.startLoadingActivity(setCurrentExperienceActivity);
		this.applicationStateService.setIsOldExperience(true); //TODO switch to false after this goes to prod
		this.presentationService.startExperience(entryPoint).subscribe();
	}

	private sortSummariesByStatusAndDate(applicationSummaries: OnboardingApplicationSummary[]): void {
		const openApplications = this.sortApplicationsByDate(
			applicationSummaries.filter((summary) => {
				if (['InProgress', 'Approved', 'Onboarded'].includes(summary.status)) {
					return summary;
				}
			}),
		);

		const actionRequired = applicationSummaries.filter((summary) => {
			if (['InReview', 'OnHold'].includes(summary.status)) {
				return summary;
			}
		});
		this.pendingApplications.set([
			...this.sortApplicationsByDate(openApplications),
			...this.sortApplicationsByDate(actionRequired),
		]);

		const closed = applicationSummaries.filter((summary) => {
			if (['Denied', 'Archived', 'Withdrawn'].includes(summary.status)) {
				return summary;
			}
		});
		this.closedApplications.set(this.sortApplicationsByDate(closed));
	}

	private sortApplicationsByDate(
		applicationSummaries: OnboardingApplicationSummary[],
	): OnboardingApplicationSummary[] {
		return applicationSummaries.sort(
			(a, b) => new Date(b.modifiedDate).getTime() - new Date(a.modifiedDate).getTime(),
		);
	}

	protected readonly NEW_APPLICATION_SUMMARY_CARDS = NEW_APPLICATION_SUMMARY_CARDS;
	protected readonly Object = Object;
	protected readonly ONBOARDING_SUMMARY_CARDS = ONBOARDING_SUMMARY_CARDS;
	protected readonly Date = Date;
}

<mat-card class="w-full overflow-hidden max-w-full rounded-xl p-2 shadow-card cursor-pointer">
	<mat-card-header class="mb-2 flex">
		<div class="flex align-center justify-between w-full">
			<div class="flex">
				<div
					class="fal pr-4"
					[ngClass]="[
						headerIcon,
						xlHeader ? 'text-6xl' : 'text-5xl',
						headerIconColor ? 'nbkc-' + headerIconColor : '',
					]"
				></div>
				<div class="flex-column">
					<div [ngClass]="xlHeader ? 'text-3xl' : 'text-2xl'">{{ title }}</div>
					<div mat-card-subtitle>
						{{ subtitle }}
						@if (secondSubtitle) {
							-- <span>{{ secondSubtitle }}</span>
						}
					</div>
				</div>
			</div>
			@if (headerRightIcon) {
				<div class="fal nbkc-blue pl-4" [ngClass]="[headerRightIcon, xlHeader ? 'text-6xl' : 'text-5xl']"></div>
			}
		</div>
		@if (hasCornerBanner) {
			<div class="triangle"></div>
			<i class="star fas fa-star"></i>
		}
	</mat-card-header>
	<mat-card-content>
		<!--Simple body text -->
		@if (bodyText) {
			<p>{{ bodyText }}</p>
		}
		<!--Any content, including a widget -->
		<ng-content></ng-content>
	</mat-card-content>
	@if (cardActions) {
		<mat-card-actions align="end">
			<!--Add buttons to app-card with cardAction directive-->
			<ng-content select="[cardActions]"></ng-content>
		</mat-card-actions>
	}
</mat-card>

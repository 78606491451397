import { Injector } from '@angular/core';
import { loadConfiguration } from '@experience/app/startup/load-configuration';
import { loadTracker } from '@experience/app/startup/load-tracker';
import { startRouteMonitor } from '@experience/app/startup/start-route-monitor';
import { loadUrlParams } from '@experience/app/startup/load-url-params';
import { overrideConsoleLog } from '@experience/app/startup/override-console-log';
import { startSocureDevicer } from '@experience/app/startup/start-socure-devicer';
import { applicationSubscriber } from './startup/application-subscriber';

export const startupFactory = (injector: Injector) => () => {
		const steps: ((x: Injector) => Promise<any>)[] = [
			loadConfiguration,
			loadTracker,
			loadUrlParams,
			startRouteMonitor,
			startSocureDevicer,
			overrideConsoleLog,
			applicationSubscriber
		];

		let index = 0;

		const runNextStep = (): Promise<any> => {
			if (index < steps.length) {
				return steps[index++](injector).then(() => runNextStep());
			}
		};

		return runNextStep();
	};

<ng-template #confirmModal>
	<h2 mat-dialog-title class="flex mb-4">
		<div class="text-5xl mr-4 ">
			<i class="fa-light fa-block-question"></i>
		</div>
		<div>Are you sure you want to start a new application?</div>
	</h2>

	<mat-dialog-content class="pb-3 mat-typography">
		<div>You created an application on {{latestApp.startDate}}.
			<button mat-button color="primary" (click)="selectExperience(latestApp.entryPoint)">Continue Application.</button>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button color="warn" class="self-end" (click)="closeDialog()">Cancel</button>
		<button mat-button color="primary" class="self-end" (click)="selectExperience(newApplication)">
			Start a new application
		</button>
	</mat-dialog-actions>
</ng-template>
